exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-all-tsx": () => import("./../../../src/pages/blog/all.tsx" /* webpackChunkName: "component---src-pages-blog-all-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-ebook-tsx": () => import("./../../../src/pages/ebook.tsx" /* webpackChunkName: "component---src-pages-ebook-tsx" */),
  "component---src-pages-events-index-tsx": () => import("./../../../src/pages/events/index.tsx" /* webpackChunkName: "component---src-pages-events-index-tsx" */),
  "component---src-pages-events-strapi-event-slug-tsx": () => import("./../../../src/pages/events/{StrapiEvent.slug}.tsx" /* webpackChunkName: "component---src-pages-events-strapi-event-slug-tsx" */),
  "component---src-pages-free-resources-tsx": () => import("./../../../src/pages/free-resources.tsx" /* webpackChunkName: "component---src-pages-free-resources-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-references-tsx": () => import("./../../../src/pages/references.tsx" /* webpackChunkName: "component---src-pages-references-tsx" */),
  "component---src-pages-services-brief-coaching-tsx": () => import("./../../../src/pages/services/brief-coaching.tsx" /* webpackChunkName: "component---src-pages-services-brief-coaching-tsx" */),
  "component---src-pages-services-brief-therapy-tsx": () => import("./../../../src/pages/services/brief-therapy.tsx" /* webpackChunkName: "component---src-pages-services-brief-therapy-tsx" */),
  "component---src-pages-services-inspirational-workshops-tsx": () => import("./../../../src/pages/services/inspirational-workshops.tsx" /* webpackChunkName: "component---src-pages-services-inspirational-workshops-tsx" */),
  "component---src-pages-services-leadership-and-sales-skills-index-tsx": () => import("./../../../src/pages/services/leadership-and-sales-skills/index.tsx" /* webpackChunkName: "component---src-pages-services-leadership-and-sales-skills-index-tsx" */),
  "component---src-pages-services-leadership-and-sales-skills-leadership-tsx": () => import("./../../../src/pages/services/leadership-and-sales-skills/leadership.tsx" /* webpackChunkName: "component---src-pages-services-leadership-and-sales-skills-leadership-tsx" */),
  "component---src-pages-services-leadership-and-sales-skills-sales-skills-tsx": () => import("./../../../src/pages/services/leadership-and-sales-skills/sales-skills.tsx" /* webpackChunkName: "component---src-pages-services-leadership-and-sales-skills-sales-skills-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-why-with-me-tsx": () => import("./../../../src/pages/why-with-me.tsx" /* webpackChunkName: "component---src-pages-why-with-me-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */)
}

