import {
  HeroSection,
  MostRequestedServices,
  ReferencesSection,
  TextSection,
} from '@hromada-mono/hromada';
import { graphql } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BlogSectionControl } from '../components/Home/BlogSectionControl';
import { FormSectionControl } from '../components/Home/FormSectionControl';
import { LetsStartSectionControl } from '../components/Home/LetsStartSection/LetsStartSection';
import { useHeroImage } from '../components/Shared/HeroSection/hooks/useHeroImage';
import { useMostRequestedServices } from '../components/Shared/hooks/useMostRequestedServices';
import Layout from '../components/Shared/Layout/Layout';
import { useReferencesSectionData } from '../components/Shared/ReferencesSection/hooks/useReferencesSectionData';
import { SEO } from '../components/Shared/SEO/SEO';
import { routes } from '../lib/routes';

const IndexPage = () => {
  const image = useHeroImage();
  const { t } = useTranslation();

  const mostRequestedServices = useMostRequestedServices();
  const referencesSectionData = useReferencesSectionData();

  return (
    <Layout>
      <SEO />
      <HeroSection
        image={image}
        title={t('hero.title.big')}
        fancy={t('hero.motto')}
        subtitle={t('hero.title.small')}
        cta={{
          url: routes.whyWithMe.to,
          label: t('hero.callToActionButton'),
        }}
      />
      <MostRequestedServices
        title={t('mostRequestedServicesBanner.title')}
        services={mostRequestedServices}
      />
      <TextSection
        title={t('solutionsOrientedBanner.title')}
        description={t('solutionsOrientedBanner.text')}
        button={t('solutionsOrientedBanner.button')}
        buttonUrl={routes.services.to}
      />
      <ReferencesSection {...referencesSectionData} />
      <LetsStartSectionControl />

      <BlogSectionControl />

      <FormSectionControl />
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default IndexPage;
