import {
  ReferencesSection,
  SEO,
  TextSection,
  TopHeader,
  WhyWithMeHero,
} from '@hromada-mono/hromada';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Shared/Layout/Layout';
import { useReferencesSectionData } from '../components/Shared/ReferencesSection/hooks/useReferencesSectionData';
import { AppreciationSection } from '../components/WhyWithMe/AppreciationSection/AppreciationSection';
import { CaptainSection } from '../components/WhyWithMe/BeTheCaptainSection/CaptainSection';
import { CooperationColorfulParagraphsSection } from '../components/WhyWithMe/CooperationColorfulParagraphsSection/CooperationColorfulParagraphsSection';
import { ProcessOfCooperationSection } from '../components/WhyWithMe/ProcessOfCooperationSection/ProcessOfCooperationSection';
import { routes } from '../lib/routes';

export const query = graphql`
  query ($language: String!) {
    strapiWhyWithMe {
      hero {
        hook
        text
        finisher
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const WhyWithMePage = (props) => {
  const {
    data: { strapiWhyWithMe },
  } = props;

  const { t } = useTranslation();
  const referencesData = useReferencesSectionData();

  return (
    <Layout>
      <TopHeader title="Why with me?" />
      <SEO title="Why with me?." />

      <WhyWithMeHero
        finisher={strapiWhyWithMe.hero.finisher}
        hook={strapiWhyWithMe.hero.hook}
        text={strapiWhyWithMe.hero.text}
        image={getImage(
          strapiWhyWithMe?.hero?.image?.localFile?.childImageSharp
        )}
      />

      <CaptainSection />

      <AppreciationSection />

      <CooperationColorfulParagraphsSection />
      <ProcessOfCooperationSection />

      <ReferencesSection {...referencesData} />
      {/* <TheWayIWorkSection /> */}

      <TextSection
        title={t('solutionsOrientedBanner.title')}
        description={t('solutionsOrientedBanner.text')}
        buttonUrl={routes.services.to}
        button={t('solutionsOrientedBanner.button')}
      />
    </Layout>
  );
};

export default WhyWithMePage;
