export interface Route {
  label: string;
  to: string;
  hideInMenu?: boolean;
}

export const routes = {
  home: {
    hideInMenu: true,
    label: 'About me',
    to: '/',
  } as Route,
  whyWithMe: {
    hideInMenu: false,
    label: 'Why with me?',
    to: '/why-with-me/',
  } as Route,
  services: {
    label: 'Services',
    to: '/services/',
  } as Route,
  references: {
    label: 'References',
    to: '/references/',
  } as Route,
  events: {
    label: 'Events',
    to: '/events',
  } as Route,
  freeResources: {
    label: 'For you',
    to: '/free-resources/',
  } as Route,
  blog: {
    label: 'Blog',
    to: '/blog/',
  } as Route,
  allBlogPosts: {
    label: 'allBlogPosts',
    to: '/blog/all',
    hideInMenu: true,
  } as Route,
  contact: {
    label: 'Contact',
    to: '/contact/',
  } as Route,
  ebook: {
    label: 'Ebook',
    to: '/ebook/',
    hideInMenu: true,
  } as Route,
  servicesFolder: {
    coaching: {
      label: 'Coaching',
      to: '/services/brief-coaching/',
    },
    therapy: {
      label: 'Therapy',
      to: '/services/brief-therapy/',
    },
    workshops: {
      label: 'Workshops',
      to: '/services/inspirational-workshops/',
    },
    leadership: {
      label: 'Therapy',
      to: '/services/leadership-and-sales-skills/',
    },
  },
};
