import { FormSection } from '@hromada-mono/hromada';
import { Box, BoxProps } from '@material-ui/core';
import axios from 'axios';
import qs from 'query-string';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

export interface FormSectionControlProps extends BoxProps {}

export const FormSectionControl = (props: FormSectionControlProps) => {
  const {} = props;
  const { t } = useTranslation();

  return (
    <Box {...props}>
      <FormSection
        header={{
          title: t('contactFormSection.title'),
          subtitle: t('contactFormSection.text'),
          bold: `Let's experience it`,
        }}
        contact={{
          tagTitle: t('contactFormSection.contactMe.subtitle'),
          title: t('contactFormSection.contactMe.title'),
          phone: {
            link: {
              label: t('contact.phone'),
              href: 'tel:+421 908 112 639',
            },
            subtitle: t('contact.available'),
          },
          email: {
            link: {
              label: t('contact.email'),
              href: 'mailto:brano@branohromada.com',
            },
            subtitle: t('contact.emailText'),
          },
        }}
        form={{
          placeholders: {
            name: t('contactFormSection.formItems.name'),
            email: t('contactFormSection.formItems.email'),
            message: t('contactFormSection.formItems.message'),
            mobile: t('contactFormSection.formItems.mobile'),
          },
          checkboxText: `Acepto el procesamiento de datos personales para poder contactarme
          y enviar avisos comerciales`,
          submitLabel: t('contactFormSection.formItems.submitButton'),
          onSubmit: async (data) => {
            axios({
              url: window.location.pathname,
              method: 'post',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              data: qs.stringify({
                ...data,
              }),
            }).then((resp) => {
              Swal.fire({
                title: 'Formulario enviado con éxito',
                icon: 'success',
              });
            });
          },
        }}
      />
    </Box>
  );
};
