import {
  OrganisationReferenceSection,
  SectionLinks,
  SEO,
  TextSection,
  TopHeader,
} from '@hromada-mono/hromada';
import { Container } from '@material-ui/core';
import { graphql } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import React from 'react';
import Layout from '../components/Shared/Layout/Layout';

export interface ReferencePageProps {
  className?: string;
  data: {
    strapiReferences: References.RootObject;
  };
}

declare namespace References {
  export interface ChildImageSharp {
    fluid: FluidObject;
  }

  export interface Image {
    childImageSharp: ChildImageSharp;
  }

  export interface Reference {
    title: string;
    image: any;
    content: string;
  }

  export interface Section {
    title: string;
    subtitle: string;
    shortText: string;
    bannerImage: any;
    references: Reference[];
  }

  export interface RootObject {
    title: string;
    subtitle: string;
    sections: Section[];
  }
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    strapiReferences {
      title
      subtitle
      sections {
        title
        subtitle
        shortText
        bannerImage {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        references {
          title
          image {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          content
        }
      }
    }
  }
`;

export const ReferencePage = (props: ReferencePageProps) => {
  const { className = '', data } = props;

  const sectionLinks = data.strapiReferences.sections.map((section) => ({
    buttonTitle: section.title,
    buttonUrl: `#${section.title}`,
    shortText: section.shortText,
    buttonType: 'primary',
  }));

  return (
    <Layout>
      {/* Header */}
      <TopHeader title="Referencias" />
      <SEO title="References" />

      <TextSection
        title={data.strapiReferences.title}
        description={data.strapiReferences.subtitle}
      />

      {/* Section links  */}
      <Container
        style={{
          marginTop: 60,
        }}
      >
        <SectionLinks links={sectionLinks} />
      </Container>

      {data.strapiReferences.sections.map((section) => (
        <OrganisationReferenceSection
          image={section.bannerImage.localFile.childImageSharp.fluid}
          title={section.title}
          subtitle={section.subtitle}
          references={section.references}
        />
      ))}
    </Layout>
  );
};

export default ReferencePage;
