import {
  ImageCardSection,
  PlainAccordionSection,
  ReferencesSection,
  Section,
  SEO,
  TextSection,
  TopHeader,
} from '@hromada-mono/hromada';
import { Container } from '@material-ui/core';
import { graphql } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import slugify from 'slugify';
import { FormSectionControl } from '../components/Home/FormSectionControl';
import { useServiceCategories } from '../components/Services/ServicesCategories/hooks/useServiceCategories';
import Layout from '../components/Shared/Layout/Layout';
import { useReferencesSectionData } from '../components/Shared/ReferencesSection/hooks/useReferencesSectionData';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    data: strapiServices {
      services {
        title
        description
        accordion {
          label
          content
        }
      }
    }
  }
`;

const ServicesPage = ({ data: { data } }) => {
  const categories = useServiceCategories();
  const referencesSectionData = useReferencesSectionData();
  const { t } = useTranslation();

  return (
    <Layout>
      <TopHeader title="Services" />
      <SEO title="Services" />

      <TextSection
        title="WHY TO INVEST IN YOURSELF?"
        description="Regardless whether you are a CEO in a big corporation, small entrepreneur, sportsman or you have some issues in your private life, the quality of your mental world is affecting your work, your relationships, your family and your happiness. Let’s explore together, what kind of quality do you want to achieve."
      />

      <ImageCardSection cards={categories} />

      <ReferencesSection {...referencesSectionData} />

      <Section>
        <Container>
          {data.services.map((item) => (
            <PlainAccordionSection
              title={item.title}
              content={item.description}
              accordion={{
                items: item.accordion.map((item) => ({
                  ...item,
                  title: item.label,
                })),
              }}
              button={{
                link: slugify(item.title, { lower: true }),
                label: t('buttons.viewMore'),
              }}
            />
          ))}
        </Container>
      </Section>

      <FormSectionControl />
    </Layout>
  );
};

export default ServicesPage;
