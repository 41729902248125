import {
  ImageAccordionSection,
  ReferencesSection,
  SEO,
  ServicesSection,
  TextSection,
  TopHeader,
} from '@hromada-mono/hromada';
import { graphql } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BlogSectionControl } from '../../../components/Home/BlogSectionControl';
import { FormSectionControl } from '../../../components/Home/FormSectionControl';
import Layout from '../../../components/Shared/Layout/Layout';
import { useReferencesSectionData } from '../../../components/Shared/ReferencesSection/hooks/useReferencesSectionData';
import { routes } from '../../../lib/routes';

export interface HabilidadesDeVentasPageProps {
  data: any;
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    data: strapiSalesSkills {
      title {
        title
        description
      }
      accordion {
        title
        description
        accordion {
          label
          content
        }
        image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      servicesTitle {
        title
        description
      }
      services {
        title
        text
        shortText
        reference {
          title
          text
          image {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

export const HabilidadesDeVentasPage = (
  props: HabilidadesDeVentasPageProps
) => {
  const referencesSectionData = useReferencesSectionData();
  const {
    data: { data },
  } = props;

  const { t } = useTranslation();

  return (
    <Layout>
      <TopHeader title="SERVICES / LEADERSHIP AND SALES SKILLS / SALES SKILLS" />
      <SEO title="SALES SKILLS" />

      <TextSection
        title={data.title.title}
        description={data.title.description}
      />

      <ImageAccordionSection
        image={data.accordion.image.localFile.childImageSharp.fluid}
        title={data.accordion.title}
        description={data.accordion.description}
        items={data.accordion.accordion.map((item) => ({
          title: item.label,
          content: item.content,
        }))}
      />
      <ReferencesSection {...referencesSectionData} />

      <ServicesSection
        title={data.servicesTitle}
        services={data.services.map((service) => ({
          title: service.title,
          content: service.text,
          shortText: service.shortText,
          image: service.image.localFile.childImageSharp.fluid,
          reference: {
            image: service.reference.image.localFile.childImageSharp.fluid,
            title: service.reference.title,
            content: service.reference.text,
            button: {
              link: service.reference?.button?.link || routes.contact.to,
              label:
                service.reference?.button?.label ||
                t('buttons.requestAppointment'),
            },
          },
        }))}
      />

      <BlogSectionControl />
      <FormSectionControl />
    </Layout>
  );
};

export default HabilidadesDeVentasPage;
