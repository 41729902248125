import { createMuiTheme } from '@material-ui/core';

const primaryColor = '#ECA93F';

export const theme = {
  name: 'Default',
  fonts: {
    secondary: 'Grand Hotel',
  },
  color: {
    background: '#ffffff',
    white: '#ffffff',
    primary: primaryColor,
    primaryDark: '#0D2C69',
    gray: '#727271 ',
    lightGray: '#E5E5E5',
    sectionBg: '#F5F5F5',
    yellow: '#ECA93F',
  },
  borderRadius: '16px',
  spacing: {
    sectionMarginTop: '14rem',
  },
  breakpoints: {
    xxs: '320px',
    xs: '375px',
    sm: '425px',
    md: '768px',
    lg: '1024px',
    xl: '1440px',
    xxl: '2560px',
  },
};

export const muiTheme = createMuiTheme();
