import {
  ImageAccordionSection,
  ReferencesSection,
  SEO,
  ServicesSection,
  TextSection,
} from '@hromada-mono/hromada';
import { graphql } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TopHeader } from '../../components/Shared/Layout/components/TopHeader/TopHeader';
import Layout from '../../components/Shared/Layout/Layout';
import { useReferencesSectionData } from '../../components/Shared/ReferencesSection/hooks/useReferencesSectionData';
import { routes } from '../../lib/routes';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    data: strapiWorkshops {
      title {
        title
        description
      }
      accordion {
        title
        description
        image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }

      services {
        title
        text
        shortText
        reference {
          title
          text
          image {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

///TODO: title of topheader treba vsade prelozit
const InspirationalWorkshopsPage = ({ data: { data } }) => {
  const referencesSectionData = useReferencesSectionData();
  const { t } = useTranslation();

  return (
    <Layout>
      <TopHeader title="Services / Inpirational workshops" />
      <SEO title="Inpirational workshops" />

      <TextSection {...data.title} />

      <ImageAccordionSection
        {...data.accordion}
        image={data.accordion.image.localFile.childImageSharp.fluid}
      />

      <ReferencesSection {...referencesSectionData} />

      <ServicesSection
        services={data.services.map((service) => {
          const payload = {
            title: service.title,
            content: service.text,
            shortText: service.shortText,
            image: service.image.localFile.childImageSharp.fluid,
            reference: null,
          };

          if (service.reference) {
            payload.reference = {
              image: service.reference?.image.localFile.childImageSharp.fluid,
              title: service.reference?.title,
              content: service.reference?.text,
              button: {
                label:
                  service?.reference?.button?.label ||
                  t('buttons.requestAppointment'),
                link: service?.reference?.button?.link || routes.contact.to,
              },
            };
          }

          return payload;
        })}
      />
    </Layout>
  );
};

export default InspirationalWorkshopsPage;
