import {
  SEO,
  TextSection,
  TopHeader,
  VideoSection,
} from '@hromada-mono/hromada';
import { graphql } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFreeVideos } from '../components/FreeResources/FreeVideosSection/hooks/useFreeVideos';
import { BlogSectionControl } from '../components/Home/BlogSectionControl';
import Layout from '../components/Shared/Layout/Layout';
import { routes } from '../lib/routes';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const FreeResourcesPage = () => {
  const { t } = useTranslation();
  const videos = useFreeVideos();

  return (
    <Layout>
      <TopHeader title="Free resources" />
      <SEO title="Free resources" />

      <TextSection
        title={t('freeResourcesPage.helpfulContent.title')}
        description={t('freeResourcesPage.helpfulContent.description')}
      />

      {/* <FreeResourcesButtonsWithTextSection /> */}
      <VideoSection videos={videos} />

      <BlogSectionControl />

      <TextSection
        title={t('solutionsOrientedBanner.title2')}
        description={t('solutionsOrientedBanner.text')}
        button={t('solutionsOrientedBanner.button')}
        buttonUrl={routes.services.to}
      />
    </Layout>
  );
};

export default FreeResourcesPage;
