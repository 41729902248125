import { useTranslation } from 'gatsby-plugin-react-i18next';
import slugify from 'slugify';
import { ServiceCategoryProps } from '../../components/ServiceCategory';
import { useServiceCategoryImages } from './useServiceCategoryImages';

export const useServiceCategories = (): ServiceCategoryProps[] => {
  const { t } = useTranslation('translations');
  const images = useServiceCategoryImages();

  const getServiceCategoryDataFromTranslations = (serviceCategory: string) => {
    const servicesTitle = t(`services.title`);
    const categoryTitle = t(`services.${serviceCategory}.title`);
    return {
      title: categoryTitle,
      image: images[serviceCategory].childImageSharp.fluid,
      shortText: t(`services.${serviceCategory}.description`),
      buttonText: t(`services.${serviceCategory}.button`),
      buttonUrl: `/services/${slugify(categoryTitle, { lower: true })}`,
    };
  };

  return [
    getServiceCategoryDataFromTranslations('coaching'),
    getServiceCategoryDataFromTranslations('therapy'),
    getServiceCategoryDataFromTranslations('leadership'),
    getServiceCategoryDataFromTranslations('workshops'),
  ];
};
