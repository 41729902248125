import {
  ClassicHeroSection,
  EventsSection,
  SEO,
  TopHeader,
} from '@hromada-mono/hromada';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Shared/Layout/Layout';
import { ReferencesSection } from '../../components/Shared/ReferencesSection/ReferencesSection';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    strapiEventsPage {
      hero {
        text
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80)
            }
          }
        }
      }
      eventsSection {
        title
        description
        events {
          event {
            type
            id
            slug
            dateTime
            title
            content
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 30)
                }
              }
            }
          }
        }
      }
    }
  }
`;

const EventsPage = (props) => {
  const {
    data: { strapiEventsPage },
  } = props;
  const { t } = useTranslation();

  return (
    <Layout>
      <TopHeader title="Events" />
      <SEO title="Events" />

      <ClassicHeroSection
        image={
          <GatsbyImage
            style={{
              height: '100%',
            }}
            imgStyle={{
              height: '100%',
            }}
            alt={''}
            image={getImage(
              strapiEventsPage.hero.image.localFile.childImageSharp
                .gatsbyImageData
            )}
          />
        }
        title={strapiEventsPage.hero.text}
      />
      <EventsSection
        viewMoreLabel={t('buttons.viewMore')}
        header={{
          title: strapiEventsPage.eventsSection.title,
          description: strapiEventsPage.eventsSection.description,
        }}
        events={strapiEventsPage.eventsSection.events.map(({ event }) => event)}
      />
      <ReferencesSection />
    </Layout>
  );
};

export default EventsPage;
